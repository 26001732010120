

























































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import UserItem from "../components/UserItem.vue";
import { AdminUser, Permission, Roles, SelectItem } from "@/core/models";

type Role = { icon: string; text: string; value: string };
const MINUTES_IN_DAY = 24 * 60;
const MINUTES_IN_MONTH = 30 * 24 * 60;

@Component({ components: { UserItem } })
export default class Users extends Vue {
  search = "";
  loading = false;
  type: "active" | "inactive" | "both" = "both";
  users: AdminUser[] = [];

  get filteredUsers() {
    if (!this.users) return [];

    const base = this.users.filter(x => {
      if (this.type === "both") return true;
      else if (this.type === "active") return x.isActive;
      else if (this.type === "inactive") return !x.isActive;
    });

    if (!this.search) return base;
    return base.filter(x =>
      `${x.email};${x.username};${x.role}`.includes(this.search),
    );
  }

  async get() {
    this.loading = true;
    try {
      this.users = (await api.get("/api/Users/GetUsers")) as AdminUser[];
    } catch (error) {
      this.users = [];
      console.log(error);
    }
    this.loading = false;
  }

  selectedUser: AdminUser | null = null;
  isLoading(user: AdminUser) {
    if (!this.selectedUser) return;
    return (
      this.selectedUser.email === user.email &&
      (this.changingRole ||
        this.togglingActive ||
        this.verifyLoading ||
        this.editingPermissions)
    );
  }

  // Role changing
  roleDialog = false;
  changingRole = false;
  selectedRole = Roles.User;
  get roles() {
    return [
      {
        icon: "mdi-account",
        text: this.$t("roles.user").toString(),
        value: Roles.User,
      },
      {
        icon: "mdi-shield",
        text: this.$t("roles.admin").toString(),
        value: Roles.Admin,
      },
      {
        icon: "mdi-shield-account",
        text: this.$t("roles.owner").toString(),
        value: Roles.Owner,
      },
    ] as Role[];
  }
  handleRoleChange(user: AdminUser) {
    this.roleDialog = true;
    this.selectedUser = user;
    this.selectedRole =
      this.roles.find(x => x.value === user.role)?.value || Roles.User;
  }
  async changeUserRole() {
    if (!this.selectedUser) return;
    if (this.selectedUser.role === this.selectedRole) return;

    this.changingRole = true;
    const otherUserEmail = this.selectedUser.email;
    const newRole = this.selectedRole;
    try {
      await api.put("/api/Users/ChangeUserRole", {
        otherUserEmail,
        newRole,
      });
      const idx = this.users.findIndex(x => x.email === otherUserEmail);
      if (idx !== -1) {
        const updatedArr = this.users.slice(0);
        updatedArr[idx] = Object.assign({}, {
          ...updatedArr[idx],
          role: newRole,
        } as AdminUser);
        this.users = updatedArr;
      }
    } catch (error) {
      console.log(error);
    }
    this.changingRole = false;
    this.roleDialog = false;
  }
  @Watch("roleDialog")
  roleDialogChanged() {
    if (this.roleDialog) return;
    this.selectedUser = null;
    this.selectedRole = Roles.User;
  }

  // Active status changing
  activeDialog = false;
  togglingActive = false;
  activeDuration = -1;
  get timeItems() {
    return [
      { text: this.$t("activeUser.indef"), value: -1 },
      { text: "15" + this.$t("activeUser.mins"), value: 15 },
      { text: "30" + this.$t("activeUser.mins"), value: 30 },
      { text: "1" + this.$t("activeUser.hours"), value: 60 },
      { text: "3" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 8 },
      { text: "6" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 4 },
      { text: "12" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 2 },
      { text: "1" + this.$t("activeUser.days"), value: MINUTES_IN_DAY },
      { text: "5" + this.$t("activeUser.days"), value: 4 * MINUTES_IN_DAY },
      { text: "10" + this.$t("activeUser.days"), value: 10 * MINUTES_IN_DAY },
      { text: "15" + this.$t("activeUser.days"), value: 15 * MINUTES_IN_DAY },
      { text: "30" + this.$t("activeUser.days"), value: MINUTES_IN_MONTH },
      { text: "2" + this.$t("activeUser.months"), value: 2 * MINUTES_IN_MONTH },
      {
        text: "12" + this.$t("activeUser.months"),
        value: 12 * MINUTES_IN_MONTH,
      },
    ] as SelectItem<number>[];
  }
  handleToggleActive(user: AdminUser) {
    this.activeDialog = true;
    this.selectedUser = user;
  }
  async toggleActive() {
    if (!this.selectedUser) return;
    const email = this.selectedUser.email;
    const isActive = this.selectedUser.isActive;
    const duration = this.activeDuration;

    this.togglingActive = true;
    try {
      const { activeUntil } = (await api.put("/api/Users/ToggleActive", {
        user: email,
        duration,
      })) as any;
      const idx = this.users.findIndex(x => x.email === email);
      const date =
        duration === -1 ? "" : new Date(activeUntil ?? "").toUTCString();
      if (idx !== -1) {
        const updatedArr = this.users.slice(0);
        updatedArr[idx] = Object.assign({}, {
          ...updatedArr[idx],
          isActive: !isActive,
          activeUntil: date,
        } as AdminUser);
        this.users = updatedArr;
      }
    } catch (error) {
      console.log("Could not toggle", error);
    }
    this.togglingActive = false;
    this.activeDialog = false;
  }
  @Watch("activeDialog")
  activeDialogChanged() {
    if (this.activeDialog) return;
    this.selectedUser = null;
    this.activeDuration = -1;
  }

  // Verifying email
  verifyLoading = false;
  handleVerifyEmail(user: AdminUser) {
    this.selectedUser = user;
    this.verifyEmail();
  }
  async verifyEmail() {
    const user = this.selectedUser;
    if (!user) return;

    this.verifyLoading = true;
    try {
      await api.get(`/api/Admin/VerifyEmail/${user.email}`);
      const idx = this.users.indexOf(user);
      this.users[idx].emailVerified = true;
    } catch (error) {
      console.log(error);
    }
    this.verifyLoading = false;
    this.selectedUser = null;
  }

  // permissions controller
  editingPermissions = false;
  permissionsDialog = false;
  removePermissionDialog = false;
  modifyPermissionDialog = false;
  endDate: string = new Date().toISOString().substring(0, 10);
  newPermissions: Permission[] = [];
  get stdPermissions(): SelectItem[] {
    return [
      {
        text: this.$t("permissions.session_training").toString(),
        value: "session_training",
        disabled: this.selectedUser?.permissions.some(
          x => x.type === "session_training",
        ),
      },
      {
        text: this.$t("permissions.seminar").toString(),
        value: "seminar",
        disabled: this.selectedUser?.permissions.some(
          x => x.type === "seminar",
        ),
      },
    ];
  }
  addPermission(type: string) {
    if (!this.selectedUser) return;
    this.newPermissions.push({
      type,
      endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
    });
  }
  removePermission(type: string) {
    if (!this.selectedUser) return;
    if (!this.newPermissions.length) return;
    this.newPermissions = this.newPermissions.filter(x => x.type !== type);
    this.removePermissionDialog = false;
  }
  modifyPermission(type: string) {
    if (!this.selectedUser) return;
    const idx = this.newPermissions.findIndex(x => x.type === type);
    if (idx === -1) return;
    this.newPermissions[idx].endDate = this.endDate;
    this.modifyPermissionDialog = false;
  }
  handlePermissions(user: AdminUser) {
    this.selectedUser = user;
    this.newPermissions = user.permissions;
    this.permissionsDialog = true;
  }
  getPermissionName(type: string) {
    return this.stdPermissions.find(x => x.value === type)?.text;
  }
  async confirmPermissions() {
    const user = this.selectedUser;
    if (!user) return;
    this.permissionsDialog = false;
    this.editingPermissions = true;
    try {
      const end = "/api/Admin/EditPermissions";
      const data = { email: user.email, permissions: this.newPermissions };
      await api.post(end, data);
    } catch (error) {
      console.log(error);
    }
    this.newPermissions = [];
    this.editingPermissions = false;
  }

  created() {
    this.get();
  }
}
