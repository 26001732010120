


















































































import Vue from "vue";
import { DateTime } from "luxon";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Roles, AdminUser } from "@/core/models";

@Component
export default class AdminPanel extends Vue {
  @Prop({ default: () => {} }) user!: AdminUser;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => false }) isLast!: boolean;
  @Getter("profile/getUserRole") currentUserRole!: string;
  @Getter("profile/getUserEmail") currentUserEmail!: string;

  get userIcon() {
    switch (this.user.role) {
      case Roles.Owner:
        return "mdi-shield-account";
      case Roles.Admin:
        return "mdi-shield";
      default:
        return "mdi-account";
    }
  }

  get activeUntil() {
    if (!this.user.activeUntil) return "";
    const date = DateTime.fromJSDate(new Date(this.user.activeUntil), {
      locale: this.$i18n.locale,
      // @ts-ignore
    }).toLocaleString(DateTime.DATETIME_MED);
    return ` ${this.$t("activeUser.until")} ${date}UTC`;
  }

  get canManage() {
    if (this.user.email == this.currentUserEmail) return false;
    if (this.currentUserRole == Roles.Owner) return true;
    return false;
  }

  get isMe() {
    return this.user.email == this.currentUserEmail;
  }
}
